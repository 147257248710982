define("discourse/plugins/discourse-ai/discourse/connectors/full-page-search-below-search-header/semantic-search", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/modifier", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/service", "discourse/components/d-toggle-switch", "discourse/controllers/full-page-search", "discourse/lib/ajax", "discourse/lib/plugin-api", "discourse/lib/search", "discourse-common/helpers/d-icon", "I18n", "discourse/plugins/discourse-ai/discourse/components/ai-indicator-wave", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _modifier, _object, _didInsert, _service, _dToggleSwitch, _fullPageSearch, _ajax, _pluginApi, _search, _dIcon, _I18n, _aiIndicatorWave, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SemanticSearch extends _component.default {
    static shouldRender(_args1, _ref) {
      let {
        siteSettings: siteSettings1
      } = _ref;
      return siteSettings1.ai_embeddings_semantic_search_enabled;
    }
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "appEvents", [_service.inject]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "searchPreferencesManager", [_service.inject]))();
    #searchPreferencesManager = (() => (dt7948.i(this, "searchPreferencesManager"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "searching", [_tracking.tracked], function () {
      return false;
    }))();
    #searching = (() => (dt7948.i(this, "searching"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "AiResults", [_tracking.tracked], function () {
      return [];
    }))();
    #AiResults = (() => (dt7948.i(this, "AiResults"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "showingAiResults", [_tracking.tracked], function () {
      return false;
    }))();
    #showingAiResults = (() => (dt7948.i(this, "showingAiResults"), void 0))();
    initialSearchTerm = this.args.outletArgs.search;
    get disableToggleSwitch() {
      if (this.searching || this.AiResults.length === 0 || this.args.outletArgs.sortOrder !== 0) {
        return true;
      }
    }
    get searchStateText() {
      // Search results:
      if (this.AiResults.length > 0) {
        if (this.showingAiResults) {
          return _I18n.default.t("discourse_ai.embeddings.semantic_search_results.toggle", {
            count: this.AiResults.length
          });
        } else {
          return _I18n.default.t("discourse_ai.embeddings.semantic_search_results.toggle_hidden", {
            count: this.AiResults.length
          });
        }
      }
      // Search loading:
      if (this.searching) {
        return _I18n.default.t("discourse_ai.embeddings.semantic_search_loading");
      }
      // Typing to search:
      if (this.AiResults.length === 0 && this.searchTerm !== this.initialSearchTerm) {
        return _I18n.default.t("discourse_ai.embeddings.semantic_search_results.new");
      }
      // No results:
      if (this.AiResults.length === 0) {
        return _I18n.default.t("discourse_ai.embeddings.semantic_search_results.none");
      }
    }
    get searchTerm() {
      if (this.initialSearchTerm !== this.args.outletArgs.search) {
        this.initialSearchTerm = undefined;
      }
      return this.args.outletArgs.search;
    }
    get searchEnabled() {
      return this.args.outletArgs.type === _fullPageSearch.SEARCH_TYPE_DEFAULT && (0, _search.isValidSearchTerm)(this.searchTerm, this.siteSettings) && this.args.outletArgs.sortOrder === 0;
    }
    toggleAiResults() {
      if (this.showingAiResults) {
        this.args.outletArgs.addSearchResults([], "topic_id");
      } else {
        this.args.outletArgs.addSearchResults(this.AiResults, "topic_id");
      }
      this.showingAiResults = !this.showingAiResults;
    }
    static #_8 = (() => dt7948.n(this.prototype, "toggleAiResults", [_object.action]))();
    resetAiResults() {
      this.AiResults = [];
      this.showingAiResults = false;
      this.args.outletArgs.addSearchResults([], "topic_id");
    }
    static #_9 = (() => dt7948.n(this.prototype, "resetAiResults", [_object.action]))();
    handleSearch() {
      if (!this.searchEnabled) {
        return;
      }
      if (this.initialSearchTerm && !this.searching) {
        return this.performHyDESearch();
      }
      this.#resetAndSearchOnEvent();
    }
    static #_10 = (() => dt7948.n(this.prototype, "handleSearch", [_object.action]))();
    performHyDESearch() {
      this.searching = true;
      this.resetAiResults();
      (0, _ajax.ajax)("/discourse-ai/embeddings/semantic-search", {
        data: {
          q: this.searchTerm
        }
      }).then(async results1 => {
        const model1 = (await (0, _search.translateResults)(results1)) || {};
        if (model1.posts?.length === 0) {
          this.searching = false;
          return;
        }
        model1.posts.forEach(post1 => {
          post1.generatedByAi = true;
        });
        this.AiResults = model1.posts;
      }).finally(() => this.searching = false);
    }
    #resetAndSearchOnEvent() {
      return (0, _pluginApi.withPluginApi)("1.15.0", api1 => {
        api1.onAppEvent("full-page-search:trigger-search", () => {
          if (!this.searching) {
            this.resetAiResults();
            return this.performHyDESearch();
          }
        });
      });
    }
    checkQueryParamsAndSearch() {
      // This check is necessary because handleSearch() isn't called
      // if query params are present and a new search has appended text.
      // It ensures AiResults are reset and searched for properly
      const searchQueryParam1 = this.router.currentRoute?.queryParams?.q;
      if (searchQueryParam1) {
        this.#resetAndSearchOnEvent();
      }
    }
    static #_11 = (() => dt7948.n(this.prototype, "checkQueryParamsAndSearch", [_object.action]))();
    static #_12 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <span {{didInsert this.checkQueryParamsAndSearch}}></span>
        {{#if this.searchEnabled}}
          <div class="semantic-search__container search-results" role="region">
            <div class="semantic-search__results" {{didInsert this.handleSearch}}>
              <div
                class="semantic-search__searching
                  {{if this.searching 'in-progress'}}"
              >
                <DToggleSwitch
                  disabled={{this.disableToggleSwitch}}
                  @state={{this.showingAiResults}}
                  class="semantic-search__results-toggle"
                  {{on "click" this.toggleAiResults}}
                />
    
                <div class="semantic-search__searching-text">
                  {{icon "discourse-sparkles"}}
                  {{this.searchStateText}}
                </div>
    
                <AiIndicatorWave @loading={{this.searching}} />
              </div>
            </div>
          </div>
        {{/if}}
      
    */
    {
      "id": "BYYsON7+",
      "block": "[[[1,\"\\n    \"],[11,1],[4,[32,0],[[30,0,[\"checkQueryParamsAndSearch\"]]],null],[12],[13],[1,\"\\n\"],[41,[30,0,[\"searchEnabled\"]],[[[1,\"      \"],[10,0],[14,0,\"semantic-search__container search-results\"],[14,\"role\",\"region\"],[12],[1,\"\\n        \"],[11,0],[24,0,\"semantic-search__results\"],[4,[32,0],[[30,0,[\"handleSearch\"]]],null],[12],[1,\"\\n          \"],[10,0],[15,0,[29,[\"semantic-search__searching\\n              \",[52,[30,0,[\"searching\"]],\"in-progress\"]]]],[12],[1,\"\\n            \"],[8,[32,1],[[16,\"disabled\",[30,0,[\"disableToggleSwitch\"]]],[24,0,\"semantic-search__results-toggle\"],[4,[32,2],[\"click\",[30,0,[\"toggleAiResults\"]]],null]],[[\"@state\"],[[30,0,[\"showingAiResults\"]]]],null],[1,\"\\n\\n            \"],[10,0],[14,0,\"semantic-search__searching-text\"],[12],[1,\"\\n              \"],[1,[28,[32,3],[\"discourse-sparkles\"],null]],[1,\"\\n              \"],[1,[30,0,[\"searchStateText\"]]],[1,\"\\n            \"],[13],[1,\"\\n\\n            \"],[8,[32,4],null,[[\"@loading\"],[[30,0,[\"searching\"]]]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/connectors/full-page-search-below-search-header/semantic-search.js",
      "scope": () => [_didInsert.default, _dToggleSwitch.default, _modifier.on, _dIcon.default, _aiIndicatorWave.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SemanticSearch;
});